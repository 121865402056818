import React, { Suspense } from "react";
import { Canvas } from "@react-three/fiber";
import { OrbitControls, useGLTF } from "@react-three/drei";

import { styled } from "@mui/material/styles";
import { Grid, Paper, CircularProgress } from "@mui/material";

const Item = styled(Paper)(({ theme }) => ({
    margin: "20px",
    boxShadow: "0 10px 40px rgb(0 0 0 / 0.2)",
    borderRadius: "20px",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    height: "65vh",
}));

function Model() {
    const { scene } = useGLTF("/untitled.glb");
    return <primitive object={scene} />;
}

const Metaverse = () => {
    return (
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <Item>
                <Suspense fallback={<CircularProgress color="secondary" />}>
                    <Canvas pixelRatio={[1, 2]} camera={{ position: [1, 1, 30], fov: 10 }}>
                        <ambientLight intensity={1} />
                        <Model />
                        <OrbitControls />
                    </Canvas>
                </Suspense>
            </Item>
        </Grid>
    );
};

export default Metaverse;
