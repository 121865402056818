import React from "react";

import { styled } from "@mui/material/styles";
import { Grid, Paper } from "@mui/material";

import AccountCard from "./AccountCard";

const Item = styled(Paper)(({ theme }) => ({
    margin: "20px",
    boxShadow: "0 10px 40px rgb(0 0 0 / 0.2)",
    borderRadius: "20px",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    height: "100%",
}));

const SocialAccounts = ({ google, facebook, twitter }) => {
    return (
        <Grid container justifyContent="center" spacing={2} paddingTop="2em">
            <Grid item xs={12} sm={4}>
                <AccountCard name="google" data={google} Item={Item} />
            </Grid>
            {/* <Grid item xs={12} sm={4}>
                <AccountCard name="facebook" data={facebook} Item={Item} />
            </Grid> */}
            <Grid item xs={12} sm={4}>
                <AccountCard name="twitter" data={twitter} Item={Item} />
            </Grid>
        </Grid>
    );
};

export default SocialAccounts;
