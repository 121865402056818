import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { Button, Grid, Paper, Box, Alert, Stack, Chip } from "@mui/material";

import { listUserProfile, resetUserProfile } from "store/actions";
import { isLoggedIn, history } from "utils";
import SocialAccounts from "./social-accounts";
import Loader from "components/Loader";
import ProfileUpdateModal from "./profile-update";

const Item = styled(Paper)(({ theme }) => ({
    margin: "20px",
    boxShadow: "0 10px 40px rgb(0 0 0 / 0.2)",
    borderRadius: "20px",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    height: "100%",
}));

function Dashboard() {
    const dispatch = useDispatch();

    const [google, setGoogle] = useState({});
    const [facebook, setFacebook] = useState({});
    const [twitter, setTwitter] = useState({});

    const { profileData, soc_accounts, isAuthenticated, loading } = useSelector(
        ({ users: { profileData, social_acc }, auth: { token }, loading }) => ({
            profileData,
            soc_accounts: social_acc,
            isAuthenticated: isLoggedIn(token),
            loading,
        })
    );

    useEffect(() => {
        if (isAuthenticated) {
            dispatch(resetUserProfile());
            dispatch(listUserProfile());
        } else {
            history.push("/");
        }
    }, []);

    useEffect(() => {
        getSocialAccounts();
    }, [soc_accounts]);

    const getSocialAccounts = () => {
        soc_accounts.map((e) => {
            if (e.provider === "google") {
                setGoogle(e);
            } else if (e.provider === "facebook") {
                setFacebook(e);
            } else if (e.provider === "twitter") {
                setTwitter(e);
            }
        });
    };

    const handleModalClose = () => {
        if (profileData?.name === "") {
            return true;
        } else {
            return false;
        }
    };

    return (
        <div style={{ backgroundColor: "white", paddingBottom: "50px" }}>
            <Loader open={loading} />
            <ProfileUpdateModal open={handleModalClose()} handleClose={handleModalClose} dispatch={dispatch} />
            <Box>
                <Button style={{ float: "right", textTransform: "capitalize" }} onClick={() => history.push("/")}>
                    Logout
                </Button>
                <Grid container justifyContent="center" spacing={1}>
                    <Grid item xs={12}>
                        <Item>
                            <img
                                style={{ width: "150px" }}
                                src={
                                    profileData?.qr_url && profileData?.qr_url !== ""
                                        ? profileData?.qr_url
                                        : "https://i.ibb.co/Q96vJLB/image.png"
                                }
                            />
                            <h6>{profileData?.name}</h6>
                            <Stack sx={{ width: "100%" }} spacing={2}>
                                <Alert
                                    severity={profileData?.email_verified ? "success" : "error"}
                                    style={{ justifyContent: "center", backgroundColor: "white" }}
                                >
                                    <h6>{profileData?.email}</h6>
                                </Alert>
                            </Stack>
                            <Grid item>
                                <Chip
                                    label={
                                        <strong>{`https://renai.thetokenlist.com/user-profile/${profileData?._id}`}</strong>
                                    }
                                />
                            </Grid>
                            <Grid item>
                                <strong>NFT status</strong>
                            </Grid>
                            <Grid item>
                                <Stack sx={{ width: "100%" }} spacing={2}>
                                    <Alert
                                        severity={
                                            profileData?.nft_status === "pending"
                                                ? "error"
                                                : profileData?.nft_status === "processing"
                                                ? "info"
                                                : profileData?.nft_status === "created"
                                                ? "success"
                                                : "error"
                                        }
                                        style={{ justifyContent: "center", backgroundColor: "white" }}
                                    >
                                        <strong style={{ textTransform: "uppercase" }}>{profileData?.nft_status}</strong>
                                    </Alert>
                                </Stack>
                            </Grid>
                            {profileData.nft_id ? (
                                <>
                                    <Grid container justifyContent="center">
                                        <Grid item>
                                            <strong>NFT Address</strong>
                                        </Grid>
                                    </Grid>
                                    <Grid container justifyContent="center">
                                        <Grid item>
                                            <Chip color="success" label={<strong>{profileData.nft_id}</strong>} />
                                        </Grid>
                                    </Grid>
                                    <br />
                                    <Grid container justifyContent="center">
                                        <Grid item>
                                            <a
                                                href={`https://explorer.solana.com/address/${profileData.nft_id}?cluster=devnet`}
                                                target="_blank"
                                            >
                                                <Button variant="outlined">
                                                    <strong style={{ textTransform: "capitalize" }}>
                                                        View On Blockchain
                                                    </strong>
                                                </Button>
                                            </a>
                                        </Grid>
                                    </Grid>
                                </>
                            ) : null}
                        </Item>
                    </Grid>
                </Grid>
                <SocialAccounts google={google} facebook={facebook} twitter={twitter} />
            </Box>
        </div>
    );
}

export default Dashboard;
