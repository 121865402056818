import React, { useState } from "react";

import { styled } from "@mui/material/styles";
import { Modal, Grid, Paper, TextField, Button } from "@mui/material";
import { updateUserProfile } from "store/actions";

const Item = styled(Paper)(({ theme }) => ({
    margin: "20px",
    boxShadow: "0 10px 40px rgb(0 0 0 / 0.2)",
    borderRadius: "20px",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
}));

const ProfileUpdateModal = ({ open, handleClose, dispatch }) => {
    const [userName, setUserName] = useState("");
    return (
        <Modal open={open} onClose={handleClose}>
            <Grid container justifyContent="center">
                <Grid item>
                    <Item>
                        <Grid container justifyContent="center">
                            <Grid item>
                                <h6>Update Your Name</h6>
                            </Grid>
                        </Grid>
                        <br />
                        <Grid container justifyContent="center">
                            <Grid item>
                                <TextField
                                    name="name"
                                    label={<strong>Name</strong>}
                                    variant="outlined"
                                    onChange={(e) => setUserName(e.target.value)}
                                />
                            </Grid>
                        </Grid>
                        <br />
                        <Grid container justifyContent="center">
                            <Grid item>
                                <Button
                                    disabled={userName === ""}
                                    variant="contained"
                                    onClick={() => dispatch(updateUserProfile({ name: userName }))}
                                >
                                    <strong>Update</strong>
                                </Button>
                            </Grid>
                        </Grid>
                    </Item>
                </Grid>
            </Grid>
        </Modal>
    );
};

export default ProfileUpdateModal;
