import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Grid } from "@mui/material";
import { sendOtp, loginWithOtp, toggleLoading, logoutUser } from "store/actions";

import LoginWithOtp from "./LoginWithOtp";
import SendOtp from "./sendOtp";

const Login = () => {
    const dispatch = useDispatch();

    const [email, setEmail] = useState("");

    useEffect(() => {
        dispatch(logoutUser());
        dispatch(toggleLoading(false));
    }, []);

    const handleSendOtp = async (value) => {
        let result = await dispatch(sendOtp(value));
        if (result) {
            toast.success("OTP sent successfully");
            setEmail(value.email);
        }
    };

    const handleOtpLogin = (value) => {
        dispatch(loginWithOtp({ email: email, otp: value.otp }));
    };
    return (
        <div >
            <div className="container-fluid bgg full-bg h-100">
                  <Grid container justifyContent="center" spacing={2} paddingTop="2em">
              <Grid item xs={7}>
              <h2 style={{color:"white",textAlign:"center",marginTop:"20px"}}>Get started with Tokenlist</h2>
              </Grid>
            <Grid item xs={12}>
                {email === "" ? <SendOtp onSubmit={handleSendOtp} /> : <LoginWithOtp onSubmit={handleOtpLogin} />}
            </Grid>
            </Grid>
            </div>
        </div>
    );
};

export default Login;
