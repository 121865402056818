import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Grid, Paper, Box, Alert, Stack, Chip, Button } from "@mui/material";

import { resetUserProfile, listUserDetails } from "store/actions";
import Loader from "components/Loader";
import SocialAccounts from "./social-accounts";
import Metaverse from "./Metaverse";

const Item = styled(Paper)(({ theme }) => ({
    margin: "20px",
    boxShadow: "0 10px 40px rgb(0 0 0 / 0.2)",
    borderRadius: "20px",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    height: "100%",
}));

function UserDetails() {
    const dispatch = useDispatch();
    const { id } = useParams();

    const [google, setGoogle] = useState({});
    const [facebook, setFacebook] = useState({});
    const [twitter, setTwitter] = useState({});

    const { profileData, soc_accounts, loading } = useSelector(({ users: { profileData, social_acc }, loading }) => ({
        profileData,
        soc_accounts: social_acc,
        loading,
    }));

    useEffect(() => {
        dispatch(resetUserProfile());
        dispatch(listUserDetails({ userId: id }));
    }, []);

    useEffect(() => {
        getSocialAccounts();
    }, [soc_accounts]);

    const getSocialAccounts = () => {
        soc_accounts.map((e) => {
            if (e.provider === "google") {
                setGoogle(e);
            } else if (e.provider === "facebook") {
                setFacebook(e);
            } else if (e.provider === "twitter") {
                setTwitter(e);
            }
        });
    };

    return (
        <div style={{ backgroundColor: "white", paddingBottom: "50px" }}>
            <Loader open={loading} />
            <Box>
                <Grid container spacing={2} justifyContent="center">
                    <Grid item xl={5} lg={5} md={5} sm={12} xs={12} paddingBottom="10px">
                        <Item>
                            <Grid container justifyContent="center" spacing={2}>
                                <Grid item>
                                    <h2>
                                        <strong>{profileData?.name}</strong>
                                    </h2>
                                </Grid>
                            </Grid>
                            <Grid container justifyContent="center">
                                <Grid item>
                                    <Stack sx={{ width: "100%" }} spacing={2}>
                                        <Alert
                                            severity={profileData?.email_verified ? "success" : "error"}
                                            style={{ justifyContent: "center", backgroundColor: "white" }}
                                        >
                                            <strong>{profileData?.email}</strong>
                                        </Alert>
                                    </Stack>
                                </Grid>
                            </Grid>
                            <Grid container justifyContent="center">
                                <Grid item>
                                    <img
                                        style={{ width: "150px" }}
                                        src={
                                            profileData?.qr_url && profileData?.qr_url !== ""
                                                ? profileData?.qr_url
                                                : "https://i.ibb.co/Q96vJLB/image.png"
                                        }
                                    />
                                </Grid>
                            </Grid>
                            <Grid container justifyContent="center">
                                <Grid item>
                                    <strong>NFT status</strong>
                                </Grid>
                            </Grid>
                            <Grid container justifyContent="center">
                                <Grid item>
                                    <Stack sx={{ width: "100%" }} spacing={2}>
                                        <Alert
                                            severity={
                                                profileData?.nft_status === "pending"
                                                    ? "error"
                                                    : profileData?.nft_status === "processing"
                                                    ? "info"
                                                    : profileData?.nft_status === "created"
                                                    ? "success"
                                                    : "error"
                                            }
                                            style={{ justifyContent: "center", backgroundColor: "white" }}
                                        >
                                            <strong style={{ textTransform: "uppercase" }}>
                                                {profileData?.nft_status}
                                            </strong>
                                        </Alert>
                                    </Stack>
                                </Grid>
                            </Grid>
                            <br />
                            <Grid container justifyContent="center">
                                <Grid item>
                                    {profileData.nft_id ? (
                                        <>
                                            <Grid container justifyContent="center">
                                                <Grid item>
                                                    <strong>NFT Address</strong>
                                                </Grid>
                                            </Grid>
                                            <Grid container justifyContent="center">
                                                <Grid item>
                                                    <Chip
                                                        style={{ fontSize: "10px" }}
                                                        color="success"
                                                        label={<strong>{profileData.nft_id}</strong>}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </>
                                    ) : null}
                                </Grid>
                            </Grid>
                            <br />
                            <Grid container justifyContent="center">
                                <Grid item>
                                    <a
                                        href={`https://explorer.solana.com/address/${profileData.nft_id}?cluster=devnet`}
                                        target="_blank"
                                        style={{ textDecoration: "none" }}
                                    >
                                        <Button variant="outlined">
                                            <strong style={{ textTransform: "capitalize" }}>View On Blockchain</strong>
                                        </Button>
                                    </a>
                                </Grid>
                            </Grid>
                        </Item>
                    </Grid>
                    <Metaverse />
                </Grid>
                &nbsp;
                <Grid container spacing={2} justifyContent="center" paddingTop="10px">
                    <SocialAccounts google={google} facebook={facebook} twitter={twitter} />
                </Grid>
            </Box>
        </div>
    );
}

export default UserDetails;
