import React from "react";
import { CircularProgress, Backdrop } from "@mui/material";

const Loader = ({ open = false }) => {
    return (
        <Backdrop open={open} style={{ zIndex: 999 }}>
            <CircularProgress color="secondary" />
        </Backdrop>
    );
};

export default Loader;
