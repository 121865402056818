import React from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import { ToastContainer } from "react-toastify";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "react-toastify/dist/ReactToastify.css";

import { history } from "utils";
import Login from "views/Login";
import Dashboard from "views/dashboard";
import Authentication from "views/authentication";
import UserDetails from "views/user-details";

const Main = () => {
    return (
        <>
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <Router history={history}>
                <Switch>
                    <Route path="/sign-in" render={(props) => <Login {...props} />} />
                    <Route path="/dashboard" render={(props) => <Dashboard {...props} />} />
                    <Route path="/authentication" render={(props) => <Authentication {...props} />} />
                    <Route path="/user-profile/:id" render={(props) => <UserDetails {...props} />} />
                    <Redirect from="/" to="/sign-in" />
                </Switch>
            </Router>
        </>
    );
};

export default Main;
