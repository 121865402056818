import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import Loader from "components/Loader";

const SendOtp = ({ onSubmit }) => {
    const { loading } = useSelector(({ loading }) => ({
        loading,
    }));
    return (
        <>
            <Loader open={loading} />
            <Grid container justifyContent={"center"} spacing={2}>
                <Grid item xs={12}>
                    <div className="container h-100">
                        <div className="row no-margin h-100">
                            <div className="bg-layer d-flex col-md-4">
                                <div className="login-box row">
                                    <h3>User login</h3>

                                    <Formik
                                        initialValues={{
                                            email: "",
                                        }}
                                        validationSchema={Yup.object().shape({
                                            email: Yup.string()
                                                .email("Must be a valid email")
                                                .max(255)
                                                .required("Email is required"),
                                        })}
                                        onSubmit={onSubmit}
                                    >
                                        {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
                                            <form onSubmit={handleSubmit}>
                                                <div className="input-group mb-3">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text" id="basic-addon1">
                                                            <i className="fas fa-at" />
                                                        </span>
                                                    </div>
                                                    <input
                                                        autoComplete="on"
                                                        type="email"
                                                        placeholder="Email"
                                                        name="email"
                                                        defaultValue={values.email}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        className="form-control"
                                                    />
                                                    {Boolean(touched.email && errors.email) ? (
                                                        <div
                                                            style={{
                                                                width: "200px",
                                                                display: "block",
                                                                marginLeft: "10px",
                                                                color: "red",
                                                                fontSize: 12,
                                                            }}
                                                        >
                                                            {errors.email}
                                                        </div>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                                <p>forget email ?</p>
                                                <button type="submit" className="btn btn-success" onClick={handleSubmit}>
                                                    Sent OTP
                                                </button>
                                            </form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </>
    );
};

export default SendOtp;
