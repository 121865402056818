export const FETCH_USER_PROFILE = `query{
    list_user_profile{
      message
      data{
        profile{
          _id
          name
          email
          email_verified
          nft_id
          nft_status
          qr_url
        }
        social_accounts{
          _id
          is_verified
          provider
          acc_id
          nft_id
          nft_status
          is_err
          err_reason
          profile_url
        }
      }
    }
  }`;

export const CREATE_NEW_SOCIAL_ACC = `mutation ($provider: String!, $accountId: String!, $userName: String!) {
  create_new_social_account(provider: $provider, account_id: $accountId, user_name: $userName) {
    message
  }
}
`;

export const UPDATE_PROFILE = `mutation ($name: String!) {
  update_profile(name: $name) {
    message
  }
}
`;

export const FETCH_USER_DETAILS = `query($userId:String!){
  list_user_details(user_id:$userId){
    message
    data{
      profile {
        _id
        name
        email
        email_verified
        nft_id
        nft_status
        qr_url
      }
      social_accounts {
        _id
        provider
        acc_id
        is_verified
        nft_id
        nft_status
        is_err
        err_reason
        profile_url
      }
    }
  }
}`;

export const SOCIAL_ACC_TO_NFT = `mutation ($socAccId: String!) {
  soc_acc_to_token(soc_acc_id: $socAccId) {
    message
  }
}
`;
