import React, { useMemo, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { Grid } from "@mui/material";
import Loader from "components/Loader";

import { apiCallAuthorized, toggleLoading } from "store/actions";
import { CREATE_NEW_SOCIAL_ACC } from "graphql";
import { history, isLoggedIn } from "utils";

const useQuery = () => {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
};

const Authentication = () => {
    const dispatch = useDispatch();
    let query = useQuery();

    const [isErr, setIsErr] = useState(false);
    const [seconds, setSeconds] = useState(5);

    const { isAuthenticated, loading } = useSelector(({ auth: { token }, loading }) => ({
        isAuthenticated: isLoggedIn(token),
        loading,
    }));

    useEffect(() => {
        if (isAuthenticated) {
            const accountId = query.get("accid");
            const provider = query.get("provider");
            const status = query.get("status");
            const userName = query.get("username");

            if (!status || !provider || !accountId || !userName) {
                history.push("/dashboard");
            }

            if (status && status === "failed") {
                setIsErr(true);
            } else {
                handleAuthSuccess({ accountId: accountId, provider: provider, userName: userName });
            }
        } else {
            history.push("/");
        }
    }, []);

    useEffect(() => {
        if (isErr) {
            seconds > 0 && setTimeout(() => setSeconds(seconds - 1), 1000);
        }

        if (seconds === 0) {
            clearTimeout();
            history.push("/dashboard");
        }
    }, [isErr, seconds]);

    const handleAuthSuccess = async (requestData) => {
        dispatch(toggleLoading(true));

        let response = await apiCallAuthorized(CREATE_NEW_SOCIAL_ACC, requestData);

        if (!response.status) {
            setIsErr(true);
            return dispatch(toggleLoading(false));
        }

        dispatch(toggleLoading(false));
        history.push("/dashboard");
    };

    return (
        <div className="container-fluid full-bg h-100">
            <Loader open={loading} />

            {isErr ? (
                <>
                    <Grid container justifyContent="center" style={{ paddingTop: "15em" }} spacing={2}>
                        <Grid item>An Error occured while autheticating your account.</Grid>
                    </Grid>
                    <Grid container justifyContent="center" spacing={2}>
                        <Grid item>
                            You will be redirected in {seconds} {seconds === 1 || seconds === 0 ? "second" : "seconds"}
                        </Grid>
                    </Grid>
                </>
            ) : (
                <Grid container justifyContent="center" style={{ paddingTop: "15em" }}>
                    <Grid item>Please wait...Do not click back button or refresh the page</Grid>
                </Grid>
            )}
        </div>
    );
};

export default Authentication;
