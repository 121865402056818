import React from "react";

import { Alert, Stack, Chip, Grid } from "@mui/material";

const AccountCard = ({ name = "", data }) => {
    return (
        <>
            <Grid container>
                <Grid item>
                    <h3 style={{ textTransform: "capitalize" }}>{name}</h3>
                </Grid>
            </Grid>

            <Grid container>
                <Grid item>
                    <Stack sx={{ width: "100%" }} spacing={2}>
                        {data?.is_verified ? (
                            <Alert severity="success" style={{ justifyContent: "center", backgroundColor: "white" }}>
                                <strong style={{ textTransform: "uppercase" }}>Verified</strong>
                            </Alert>
                        ) : (
                            <Alert severity="error" style={{ justifyContent: "center", backgroundColor: "white" }}>
                                <strong style={{ textTransform: "uppercase" }}>Not Verified</strong>
                            </Alert>
                        )}
                    </Stack>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item>
                    {data?.profile_url && data?.profile_url !== "" ? (
                        <>
                            <Chip label={<strong>{data?.profile_url}</strong>} />
                            <br />
                        </>
                    ) : null}
                </Grid>
            </Grid>
        </>
    );
};

export default AccountCard;
