import { LIST_USER_PROFILE, RESET_USER_PROFILE } from "../types";

const INITIAL_STATE = {
    profileData: {},
    social_acc: [],
};

const userReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case LIST_USER_PROFILE:
            return payload;

        case RESET_USER_PROFILE:
            return INITIAL_STATE;

        default:
            return state;
    }
};

export default userReducer;
