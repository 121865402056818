import { post } from "services";
import { TOGGLE_LOADING, TOGGLE_MODAL, LIST_USER_PROFILE, RESET_USER_PROFILE } from "../types";
import { FETCH_USER_PROFILE, CREATE_NEW_SOCIAL_ACC, UPDATE_PROFILE, FETCH_USER_DETAILS, SOCIAL_ACC_TO_NFT } from "graphql";
import { history } from "utils";

export const listUserProfile = () => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });
        dispatch({ type: TOGGLE_MODAL, payload: false });

        let apiResponse = await post("", {
            query: FETCH_USER_PROFILE,
        });

        if (apiResponse.data.customStatus) {
            let {
                list_user_profile: { data },
            } = apiResponse.data.data;

            dispatch({
                type: LIST_USER_PROFILE,
                payload: {
                    profileData: data.profile,
                    social_acc: data.social_accounts,
                },
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetUserProfile = () => {
    return (dispatch) => {
        dispatch({ type: RESET_USER_PROFILE });
    };
};

export const createNewSocialAccount = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });
        dispatch({ type: TOGGLE_MODAL, payload: false });

        let apiResponse = await post("", {
            query: CREATE_NEW_SOCIAL_ACC,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            history.push("/dashboard");
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const updateUserProfile = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });
        dispatch({ type: TOGGLE_MODAL, payload: false });

        let apiResponse = await post("", {
            query: UPDATE_PROFILE,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            dispatch(resetUserProfile());
            dispatch(listUserProfile());
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const listUserDetails = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });
        dispatch({ type: TOGGLE_MODAL, payload: false });

        let apiResponse = await post("", {
            query: FETCH_USER_DETAILS,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            let {
                list_user_details: { data },
            } = apiResponse.data.data;

            dispatch({
                type: LIST_USER_PROFILE,
                payload: {
                    profileData: data.profile,
                    social_acc: data.social_accounts,
                },
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const socialAccToNft = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });
        dispatch({ type: TOGGLE_MODAL, payload: false });

        let apiResponse = await post("", {
            query: SOCIAL_ACC_TO_NFT,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            dispatch(resetUserProfile());
            dispatch(listUserProfile());
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};
