import React from "react";
import { useDispatch } from "react-redux";

import { Button, Grid, Alert, Stack, Chip } from "@mui/material";

import { socialAccToNft } from "store/actions";

const AccountCard = ({ name = "", data, Item }) => {
    const dispatch = useDispatch();
    return (
        <Item style={{ padding: "40px" }}>
            <h4 style={{ textTransform: "capitalize" }}>{name}</h4>

            <Stack sx={{ width: "100%" }} spacing={2}>
                {data?.is_verified ? (
                    <Alert severity="success" style={{ justifyContent: "center", backgroundColor: "white" }}>
                        <strong style={{ textTransform: "uppercase" }}>Verified</strong>
                    </Alert>
                ) : (
                    <Alert severity="error" style={{ justifyContent: "center", backgroundColor: "white" }}>
                        <strong style={{ textTransform: "uppercase" }}>Not Verified</strong>
                    </Alert>
                )}
            </Stack>

            {data?.profile_url && data?.profile_url !== "" ? (
                <>
                    <Chip label={<strong>{data?.profile_url}</strong>} />
                    <br />
                </>
            ) : null}

            {!data?.is_verified ? (
                <>
                    <strong>click here to verify your data account</strong>
                    <br />
                </>
            ) : null}
            <br />

            {data?.is_verified ? (
                <>
                    {data?.is_err && !data._is_err ? (
                        <strong style={{ color: "red" }}>{data?.err_reason}</strong>
                    ) : (
                        <>
                            {/* <Grid container justifyContent="center">
                                <Grid item>
                                    <strong>NFT status</strong>
                                </Grid>
                            </Grid>
                            <Grid container justifyContent="center">
                                <Grid item>
                                    <Stack sx={{ width: "100%" }} spacing={2}>
                                        <Alert
                                            severity={
                                                data?.nft_status === "pending"
                                                    ? "error"
                                                    : data?.nft_status === "processing"
                                                    ? "info"
                                                    : data?.nft_status === "created"
                                                    ? "success"
                                                    : "error"
                                            }
                                            style={{ justifyContent: "center", backgroundColor: "white" }}
                                        >
                                            <strong style={{ textTransform: "uppercase" }}>{data?.nft_status}</strong>
                                        </Alert>
                                    </Stack>
                                </Grid>
                            </Grid>
                            <br/> */}

                            {data?.nft_id && data?.nft_id !== "" ? (
                                <>
                                    <Grid container justifyContent="center">
                                        <Grid item>
                                            <strong>NFT Address</strong>
                                        </Grid>
                                    </Grid>
                                    <Grid container justifyContent="center">
                                        <Grid item>
                                            <Chip color="success" label={<strong>{data.nft_id}</strong>} />
                                        </Grid>
                                    </Grid>
                                    <br />
                                    <Grid container justifyContent="center">
                                        <Grid item>
                                            <a
                                                href={`https://explorer.solana.com/address/${data.nft_id}?cluster=devnet`}
                                                target="_blank"
                                            >
                                                <Button variant="outlined">
                                                    <strong style={{ textTransform: "capitalize" }}>
                                                        View On Blockchain
                                                    </strong>
                                                </Button>
                                            </a>
                                        </Grid>
                                    </Grid>
                                </>
                            ) : (
                                <Button
                                    variant="contained"
                                    onClick={() => dispatch(socialAccToNft({ socAccId: data?._id }))}
                                >
                                    <strong>Convert to NFT</strong>
                                </Button>
                            )}
                        </>
                    )}
                </>
            ) : (
                <a href={`https://api-renai.thetokenlist.com/auth/${name}`}>
                    <Button variant="contained">
                        <strong>Verify</strong>
                    </Button>
                </a>
            )}
        </Item>
    );
};

export default AccountCard;
